<template>
    <div>
        <h1 style="text-align:center; font-size:2em;">查看互动详情信息</h1>
        <a-descriptions bordered :column="2" style="width:80%; margin-left:10%;">
            <a-descriptions-item label="ID">
                {{DataSource.ID}}
            </a-descriptions-item>
            <a-descriptions-item label="机构ID">
                {{DataSource.CustomerID}}
            </a-descriptions-item>
            <a-descriptions-item label="名称">
                {{DataSource.Name}}
            </a-descriptions-item>
            <a-descriptions-item label="创建人">
                {{DataSource.UserID}}
            </a-descriptions-item>
            <a-descriptions-item label="发布状态">
                {{DataSource.ReleaseStatus}}
            </a-descriptions-item>
            <a-descriptions-item label="充值金额">
                {{DataSource.RechargeMoney}}
            </a-descriptions-item>
            <a-descriptions-item label="奖励金额">
                {{DataSource.RewardsMoney}}
            </a-descriptions-item>
            <a-descriptions-item label="订 单 号">
                {{DataSource.OrderNumber}}
            </a-descriptions-item>
            <a-descriptions-item label="激励类型">
                {{DataSource.RewardsType}}
            </a-descriptions-item>
            <a-descriptions-item label="账户金额">
                {{DataSource.AccountMoney}}
            </a-descriptions-item>
            <a-descriptions-item label="活动Id">
                {{DataSource.ActivityID}}
            </a-descriptions-item>
            <a-descriptions-item label="录入时间" :span="1">
                {{DataSource.InputTime|formatDate}}
            </a-descriptions-item>
            <a-descriptions-item label="开始时间" :span="1">
                {{DataSource.StartTime|formatDate}}
            </a-descriptions-item>
            <a-descriptions-item label="结束时间" :span="1">
                {{DataSource.EndTime|formatDate}}
            </a-descriptions-item>
        </a-descriptions>
        <a-button type="primary" @click="PreviousPage" style="margin-left:42%; width:200px;">返回首页</a-button>
    </div>
   
</template>
<style>
    .tab {
        width: 200px;
    }
</style>
<script>
    import http from "@/Plugin/Http.js";
    import moment from 'moment'
    //import util from "@/Plugin/util.js";
    //import formatDate from "@/Plugin/formatDate.js";
    export default {
        data() {
            return {
                ID: "",
                DataSource: [],
                time: "1618535457143",
            }
        },
        //filters: {
        //    formatDate: function (value) {
        //        let date = new Date(value);
        //        let y = date.getFullYear();
        //        let MM = date.getMonth() + 1;
        //        MM = MM < 10 ? ('0' + MM) : MM;
        //        let d = date.getDate();
        //        d = d < 10 ? ('0' + d) : d;
        //        let h = date.getHours();
        //        h = h < 10 ? ('0' + h) : h;
        //        let m = date.getMinutes();
        //        m = m < 10 ? ('0' + m) : m;
        //        let s = date.getSeconds();
        //        s = s < 10 ? ('0' + s) : s;
        //        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        //    }
        //},
        props: {
            //组件属性
        },
        //过滤器
        filters: {
            formatDate: function (value) {
                return moment(value).format('YYYY-MM-DD hh:mm:ss')
            }
        },
        methods: {//方法
            GetViewDetails: function () {
                var self = this;
                var op = {
                    url: "/Interactive/GetViewDetails",
                    data: {
                        ID: self.ID
                    },
                    OnSuccess: function (data) {
                        self.DataSource = data.data;
                        console.log(self.DataSource);
                    },
                };
                http.Post(op);
            },
            PreviousPage: function () {
                this.$router.replace("/InteractiveModel/index");
            }
            //switchTime(Stamp) {
            //    let timeStamp = Number(Stamp)
            //    var time = new Date(timeStamp);
            //    var y = time.getFullYear();
            //    var m = time.getMonth() + 1;
            //    var d = time.getDate();
            //    var h = time.getHours();
            //    var mm = time.getMinutes();
            //    var s = time.getSeconds();
            //    return y + '-' + this.judgeNum(m) + '-' + this.judgeNum(d) + ' ' + this.judgeNum(h) + ':' + this.judgeNum(mm) + ':' + this.judgeNum(s);
            //},
            //judgeNum(num) {
            //    if (num < 10) {
            //        return '0' + num
            //    } else {
            //        return num
            //    }
            //},
            //convertToDate: (time) => {
            //    console.log(time);
            //    var temp = new Date(util.TimeStamptoDateTime(time, 'yyyy-MM-dd hh:mm:ss'));
            //    console.log(temp);
            //    return util.formatDate3(temp);
            //},
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            this.ID = this.$route.query.ID;
            this.GetViewDetails();
            //生命周期，组装完成
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }

</script>